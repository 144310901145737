define('keygen/components/kg-metric-card', ['exports', 'keygen/components/base', 'ember', 'moment', 'npm:query-string', 'lodash'], function (exports, _keygenComponentsBase, _ember, _moment, _npmQueryString, _lodash) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _keygenComponentsBase['default'].extend({
    session: service('session'),
    store: service('store'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('metric', null);
      this.set('data', []);
      this.set('options', {});
      this.set('hitMaxPagination', false);
      this.set('isLoading', true);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
      var accountId = this.get('session.data.authenticated.aid');
      var token = this.get('session.data.authenticated.tok');
      var metric = this.get('type');

      // FIXME(ezekg) Can't figure out how to use this action on the metric model
      fetch(baseUrl + '/accounts/' + accountId + '/metrics/actions/count?' + _npmQueryString['default'].stringify({ metrics: [metric] }, { arrayFormat: 'bracket' }), {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
          "Authorization": 'Bearer ' + token
        }
      }).then(function (res) {
        return res.json();
      }).then(function (body) {
        if (_this.isDestroyed) {
          return;
        }

        var metrics = body.meta;

        var dates = _this._getDatesForPeriod();

        // Partition metrics into current period and previous period
        var currentPeriod = _moment['default'].utc().subtract(7, 'days');
        var paritionedMetrics = dates.reduce(function (res, date) {
          var isAfter = (0, _moment['default'])(date).isSameOrAfter(currentPeriod, 'day');

          res[isAfter ? 0 : 1][date] = metrics[date];

          return res;
        }, [{}, {}]);

        var _paritionedMetrics = _slicedToArray(paritionedMetrics, 2);

        var currentMetrics = _paritionedMetrics[0];
        var previousMetrics = _paritionedMetrics[1];

        // Get the displayed metric
        var currentMetric = (0, _lodash.sum)((0, _lodash.values)(currentMetrics)) || 0;
        var previousMetric = (0, _lodash.sum)((0, _lodash.values)(previousMetrics)) || 0;

        // Get the current trend
        var trend = undefined;
        if (currentMetric || previousMetric) {
          var increase = currentMetric - previousMetric;

          if (previousMetric > 0) {
            trend = Math.round(increase / previousMetric * 100);
          } else {
            trend = 100.0;
          }
        } else {
          trend = 0.0;
        }

        // Create data that is formatted for chart
        var data = (0, _lodash.map)(dates, function (date) {
          return {
            x: +_moment['default'].utc(date),
            y: metrics[date] || 0
          };
        });

        _this.setProperties({
          metric: currentMetric,
          isLoading: false,
          isError: false,
          error: null,
          trend: trend,
          data: data
        });
      })['catch'](function (e) {
        if (_this.isDestroyed) {
          return;
        }

        _this.setProperties({
          isLoading: false,
          isError: true,
          error: e.message,
          data: null
        });
      });
    },

    _getDatesForPeriod: function _getDatesForPeriod() {
      var startOfPeriod = _moment['default'].utc().subtract(13, 'days');
      var endOfPeriod = _moment['default'].utc();

      var days = [];

      var day = startOfPeriod;

      while (day <= endOfPeriod) {
        days.push(day.utc().startOf('day').format("YYYY-MM-DD"));
        day = day.clone().add(1, 'day');
      }

      return days;
    }
  });
});